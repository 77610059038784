import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { BusinessDetailStatus, BusinessDetailTag } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  BusinessDetailModel,
  UserModel,
} from "@obtainly-v2/schema";
import { FormField } from "components/common";
import { useToast } from "hooks";
import { useEffect, useState } from "react";
import { RiCheckboxCircleFill, RiFileCopyFill } from "react-icons/ri";
import { useMutation } from "react-query";
import { verifyService } from "services";
import { businessDetailService } from "services/BusinessDetail";
import { obscureString } from "utils";

interface BVNFormProps {
  admin?: AdministratorModel;
  user?: UserModel;
  detail?: BusinessDetailModel;
  onUpdate?: VoidFunction;
}

export const BVNForm: React.FC<BVNFormProps> = ({
  admin,
  user,
  detail,
  onUpdate,
}) => {
  const { toast } = useToast();
  const [bvn, setBvn] = useState<string>(detail?.value ?? "");
  const { onCopy, hasCopied } = useClipboard(bvn);
  const [verified, setVerified] = useState<boolean>(
    detail?.status === BusinessDetailStatus.Verified
  );
  const verifyBvnMutation = useMutation(verifyService.bvn);
  const createBusinessDetailsMutation = useMutation(
    businessDetailService.create
  );

  const saveBvn = () => {
    if (user) {
      verifyBvnMutation.mutate(
        { identifier: bvn, options: "" },
        {
          onSuccess: ({ data }) => {
            if (
              data?.verified &&
              data.verified?.first_name &&
              data.verified?.last_name
            ) {
              const firstNameMatches =
                String(data.verified.first_name).toLowerCase().trim() ===
                String(user.firstName).toLowerCase().trim();
              const lastNameMatches =
                String(data.verified.last_name).toLowerCase().trim() ===
                String(user.lastName).toLowerCase().trim();
              if (firstNameMatches && lastNameMatches) {
                createBusinessDetailsMutation.mutate(
                  {
                    tag: BusinessDetailTag.BVN,
                    value: bvn,
                    status: BusinessDetailStatus.Verified,
                    businessId: user.businessId,
                  },
                  {
                    onSuccess: () => {
                      setVerified(true);
                      toast({
                        status: "success",
                        description: "BVN verification successful.",
                      });
                      onUpdate?.();
                    },
                  }
                );
              } else {
                toast({
                  status: "error",
                  description: `Your name 
                  (${data.verified.first_name} ${data.verified.last_name}) 
                  on BVN record did not match profile name 
                  (${user.firstName} ${user.lastName})`,
                  duration: 10000,
                });
              }
            } else {
              toast({
                status: "error",
                description: "We could not verify your BVN.",
              });
            }
          },
          onError: (error) => {
            toast({
              status: "error",
              description: "We could not verify your BVN.",
            });
          },
        }
      );
    }
  };

  const isLoading =
    verifyBvnMutation.isLoading || createBusinessDetailsMutation.isLoading;

  useEffect(() => {
    setBvn(detail?.value || "");
    setVerified(detail?.status === BusinessDetailStatus.Verified);
  }, [detail]);

  return verified ? (
    <Flex alignItems="start" gap="6px">
      <Icon as={RiCheckboxCircleFill} color="success.500" fontSize="18px" />
      <Box>
        <Text color="success.600">BVN has been verified</Text>
        <Flex alignItems="center" gap="6px" mt="4px">
          <Text color="gray.800">{!!admin ? bvn : obscureString(bvn)}</Text>
          {!!admin && (
            <>
              {hasCopied ? (
                <Text fontSize="sm" color="success.600">
                  Copied!
                </Text>
              ) : (
                <IconButton
                  aria-label="copy"
                  size="xs"
                  color="primary.500"
                  bgColor="primary.50"
                  _hover={{ bgColor: "primary.50" }}
                  icon={<RiFileCopyFill />}
                  onClick={() => onCopy()}
                />
              )}
            </>
          )}
        </Flex>
      </Box>
    </Flex>
  ) : user ? (
    <>
      <FormField
        size="sm"
        value={bvn}
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
          setBvn(target.value)
        }
      />
      <Button
        type="button"
        mt="15px"
        size="sm"
        colorScheme="primary"
        isLoading={isLoading}
        isDisabled={isLoading || !bvn}
        onClick={saveBvn}
      >
        Save
      </Button>
    </>
  ) : (
    <Flex alignItems="center" gap="6px">
      <Text color="gray.600">No verified BVN found.</Text>
    </Flex>
  );
};
