export { getStaticProps } from "lib/pages/getStaticPropsUser";
import { Box, VStack } from "@chakra-ui/react";
import { BusinessDetailTag, CACRegistrationType } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  BusinessDetailModel,
  UserModel,
} from "@obtainly-v2/schema";
import { BVNForm } from "components/business";
import { ContentLoader } from "components/common";
import { Card, CardBody, CardHeader } from "components/common/Card";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { businessService } from "services";
import { BusinessDetailForm } from "./BusinessDetailForm";
import { BusinessDetailList } from "./BusinessDetailList";

interface BusinessVerificationProps {
  businessId: string;
  user?: UserModel;
  admin?: AdministratorModel;
}

export const BusinessVerification: React.FC<BusinessVerificationProps> = ({
  businessId,
  user,
  admin,
}) => {
  const {
    data: business,
    isLoading,
    refetch,
  } = useQuery(
    `business_read_${businessId}`,
    () => businessService.read(`${businessId}`),
    { enabled: !!businessId }
  );

  const { businessDetails, detailMap } = useMemo(() => {
    const map = new Map<string, Array<BusinessDetailModel>>();
    const _details: Partial<Record<BusinessDetailTag, BusinessDetailModel>> =
      {};

    business?.details?.forEach((detail) => {
      if (map.has(detail.tag)) {
        const newArray: Array<BusinessDetailModel> = map?.get(detail.tag) || [];
        newArray?.push(detail);
        map.set(detail.tag, newArray);
      } else {
        map.set(detail.tag, [detail]);
      }
      _details[detail.tag] = detail;
    });

    return { businessDetails: _details, detailMap: map };
  }, [business?.details]);

  if (!business || isLoading) {
    return (
      <Box>
        <ContentLoader
          isLoading={isLoading}
          contentUnavailable={!business}
          errorMessage="Unable to load data."
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader heading="Bank Verification Number" />
            <BVNForm
              user={user!}
              detail={
                Array.from(
                  detailMap.get(BusinessDetailTag.BVN)?.values() || []
                )?.[0]
              }
              admin={admin}
              onUpdate={refetch}
            />
          </CardBody>
        </Card>
      </Box>

      {/* passport photograph */}
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader heading="Passport" description="Passport photograph" />
            <BusinessDetailList
              label="Passport"
              business={business}
              tag={BusinessDetailTag.Passport}
              businessDetails={Array.from(
                detailMap.get(BusinessDetailTag.Passport)?.values() || []
              )}
              isAdmin={!!admin}
              refetch={refetch}
            />
          </CardBody>
        </Card>
      </Box>

      {/* identity card */}
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader
              heading="Identity Card"
              description="Driver's License, NIN, International Passport or Voter's Card"
            />
            <BusinessDetailList
              label="Identity Card"
              business={business}
              tag={BusinessDetailTag.IdentityCard}
              businessDetails={Array.from(
                detailMap.get(BusinessDetailTag.IdentityCard)?.values() || []
              )}
              isAdmin={!!admin}
              refetch={refetch}
            />
          </CardBody>
        </Card>
      </Box>

      {/* registration type */}
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader
              heading="CAC Registration"
              description="Is your organization registered by CAC as a Business Name or Company?"
            />
            <BusinessDetailForm
              label="CAC registration type"
              business={business}
              tag={BusinessDetailTag.RegType}
              businessDetail={businessDetails?.[BusinessDetailTag.RegType]}
              isAdmin={!!admin}
              onUpdate={refetch}
              type="radio"
              options={[
                {
                  label: "Business Name Registration",
                  value: CACRegistrationType.Business,
                },
                {
                  label: "Company Registration",
                  value: CACRegistrationType.Company,
                },
              ]}
            />
          </CardBody>
        </Card>
      </Box>

      {/* CAC Certificate */}
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader
              heading="CAC Certificate"
              description="Business name or Company certificate"
            />
            <BusinessDetailList
              label="CAC Certificate"
              business={business}
              tag={BusinessDetailTag.CACCertificate}
              businessDetails={Array.from(
                detailMap.get(BusinessDetailTag.CACCertificate)?.values() || []
              )}
              isAdmin={!!admin}
              refetch={refetch}
              allowMultipleFiles
            />
          </CardBody>
        </Card>
      </Box>

      {/* CAC status report */}
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader
              heading="Status Report"
              description="CAC status report document or it's equivalent e.g Form CAC 2/7 (for companies) and CAC documentation (for registered business names)"
            />
            <BusinessDetailList
              label="Status report"
              business={business}
              tag={BusinessDetailTag.CACStatus_Report}
              businessDetails={Array.from(
                detailMap.get(BusinessDetailTag.CACStatus_Report)?.values() ||
                  []
              )}
              isAdmin={!!admin}
              refetch={refetch}
              allowMultipleFiles
            />
          </CardBody>
        </Card>
      </Box>

      {/* company requirements */}
      {businessDetails?.[BusinessDetailTag.RegType]?.value ===
        CACRegistrationType.Company && (
        <>
          <Box>
            <Card mt="10px">
              <CardBody>
                <CardHeader
                  heading="Memorandum and Article of Association"
                  description="CAC Document"
                />
                <BusinessDetailList
                  label="Memorandum & Article of Assoc."
                  tag={BusinessDetailTag.CACMemorandum}
                  business={business}
                  businessDetails={Array.from(
                    detailMap.get(BusinessDetailTag.CACMemorandum)?.values() ||
                      []
                  )}
                  isAdmin={!!admin}
                  refetch={refetch}
                  allowMultipleFiles
                />
              </CardBody>
            </Card>
          </Box>
        </>
      )}

      {/* Utility bill */}
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader
              heading="Utility Bill"
              description="Utility Bill not more than 3 months old"
            />
            <BusinessDetailList
              label="Utility Bill"
              business={business}
              tag={BusinessDetailTag.UtilityBill}
              businessDetails={Array.from(
                detailMap.get(BusinessDetailTag.UtilityBill)?.values() || []
              )}
              isAdmin={!!admin}
              refetch={refetch}
              allowMultipleFiles={!!admin}
            />
          </CardBody>
        </Card>
      </Box>

      {/* Other documents */}
      <Box>
        <Card mt="10px">
          <CardBody>
            <CardHeader
              heading="Others"
              description="Any other document can be uploaded here. Please upload a board resolution here, if you hold less than 51% of the company shares."
            />
            <BusinessDetailList
              label="Others"
              business={business}
              tag={BusinessDetailTag.Others}
              businessDetails={Array.from(
                detailMap.get(BusinessDetailTag.Others)?.values() || []
              )}
              isAdmin={!!admin}
              refetch={refetch}
              allowMultipleFiles
            />
          </CardBody>
        </Card>
      </Box>

      {/* other obsolete documents */}
      {!!admin && (
        <Box>
          <Card mt="10px">
            <CardBody>
              <CardHeader
                heading="Deprecated Documents"
                description="Documents that might have been uploaded before requirement changed. These are shown to admin only."
              />
              <VStack spacing="10px" alignItems="flex-start">
                <BusinessDetailList
                  label="Form CAC2/CAC7"
                  business={business}
                  tag={BusinessDetailTag.CACForm}
                  businessDetails={Array.from(
                    detailMap.get(BusinessDetailTag.CACForm)?.values() || []
                  )}
                  isAdmin={!!admin}
                  refetch={refetch}
                  allowMultipleFiles
                />
              </VStack>
            </CardBody>
          </Card>
        </Box>
      )}
    </Box>
  );
};
