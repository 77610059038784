import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { routes } from "config/routes";
import { useAuth, useProfileCompletion } from "hooks";
import Link from "next/link";
import { FC } from "react";
import { IconType } from "react-icons";
import { MdVerified } from "react-icons/md";
import { RiFileList2Fill, RiUploadCloudFill } from "react-icons/ri";
interface ProfileStatProps extends TextProps {
  description: string;
  icon: IconType;
}
const ProfileStat = (props: ProfileStatProps) => {
  const { icon: Icon, description, color } = props;
  return (
    <Flex align="center" gap="3px" color={color}>
      <Box>
        <Icon />
      </Box>
      <Text>{description}</Text>
    </Flex>
  );
};

export const BusinessProfileCTA: FC = () => {
  const {
    auth: { user },
  } = useAuth();

  const { unsetCount, verifiedCount, unverifiedCount, requiredDocumentCount } =
    useProfileCompletion(user?.business);

  const verifying =
    unsetCount === 0 &&
    verifiedCount + unverifiedCount === requiredDocumentCount;

  return (
    <Alert
      mb="20px"
      p="20px"
      status="info"
      bg="#ECF4FF"
      borderRadius="lg"
      alignItems="flex-start"
      flexDir="column"
      boxShadow="0px 4px 0px #D1E5FF"
    >
      <AlertIcon
        as={RiFileList2Fill}
        color="#016DFF"
        width="36px"
        height="36px"
      />

      <Box mt="10px">
        <AlertTitle
          fontSize="2xl"
          fontFamily="archivo"
          fontWeight="semibold"
          color="primary.700"
        >
          {verifying ? "Profile verification" : "Complete your profile"}
        </AlertTitle>

        {verifying ? (
          <>
            <Text mt="4px">
              We are verifying the documents you submitted. This would only take
              a short while.
            </Text>
            <Box mt="6px">
              <ProfileStat
                description={`${verifiedCount} of ${
                  requiredDocumentCount - unsetCount
                } verified`}
                icon={MdVerified}
                color="success.600"
              />
            </Box>
          </>
        ) : (
          <>
            <Text mt="4px">
              You&apos;re almost there! Complete your business profile to start
              receiving funds.
            </Text>
            <Box mt="6px">
              <ProfileStat
                description={`${requiredDocumentCount - unsetCount} of
                    ${requiredDocumentCount} uploaded`}
                icon={RiUploadCloudFill}
                color="warning.600"
              />
            </Box>
            <Flex align="center" gridGap="6px" mt="10px">
              <Link href={routes.user.onboarding}>
                <a>
                  <Button size="sm" colorScheme="primary">
                    Complete Profile
                  </Button>
                </a>
              </Link>
            </Flex>
          </>
        )}
      </Box>
    </Alert>
  );
};
