import {
  Box,
  Button,
  Grid,
  GridItem,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import { BusinessDetailTag } from "@obtainly-v2/enums";
import { FormField } from "components/common";
import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
} from "react-hook-form";
import { RiCloseLine } from "react-icons/ri";
import { BusinessFormInputType } from "./BusinessForm";

interface BusinessDetailsFormProp<T extends Record<string, any>> {
  control: Control<T>;
  errors?: FieldErrors<T>;
}

export const BusinessDetailsForm: React.FC<
  BusinessDetailsFormProp<BusinessFormInputType>
> = ({ control, errors }) => {
  const { fields, remove, append } = useFieldArray({
    name: "details",
    control,
  });

  const appendNew = () => {
    append({
      tag: "",
      value: "",
      businessId: null,
      status: "active",
    });
  };

  return (
    <>
      <VStack gridGap="2" align="stretch">
        {fields?.map((detail, index) => {
          return (
            !!detail && (
              <React.Fragment key={detail.id}>
                <Grid templateColumns="2fr 3fr auto" gap="2" alignItems="start">
                  <GridItem>
                    <Controller
                      name={`details.${index}.tag`}
                      control={control}
                      render={({ field: { ref, onChange, ...rest } }) => (
                        <FormField
                          as="autocomplete"
                          placeholder="Select Tag"
                          options={Object.entries(BusinessDetailTag).map(
                            ([key, value]) => ({ label: key, value })
                          )}
                          errorMessage={
                            errors?.details?.find?.(
                              (_, currentIndex) => currentIndex == index
                            )?.tag?.message
                          }
                          onChange={(data: any) => onChange({ target: data })}
                          {...rest}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem>
                    <Controller
                      name={`details.${index}.value`}
                      control={control}
                      render={({ field }) =>
                        !!field && (
                          <FormField
                            type="text"
                            placeholder="Enter value"
                            autoComplete="detail-value"
                            errorMessage={
                              errors?.details?.[index]?.value?.message
                            }
                            {...field}
                          />
                        )
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <IconButton
                      variant="outline"
                      colorScheme="gray"
                      onClick={() => remove(index)}
                      aria-label="Remove detail"
                      title="Remove detail"
                      icon={<RiCloseLine />}
                    />
                  </GridItem>
                </Grid>
              </React.Fragment>
            )
          );
        })}
        <Box>
          <Button onClick={appendNew}>Add detail</Button>
        </Box>
      </VStack>
    </>
  );
};
