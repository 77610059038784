import { Button, VStack } from "@chakra-ui/react";
import { BusinessStatus, BusinessType } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  BusinessModel,
  UserModel,
} from "@obtainly-v2/schema";
import { FormBaseProps, FormField, FormGroup } from "components/common";
import { useFormWithSchema } from "hooks";
import { useToast } from "hooks/useToast";
import { capitalize } from "lodash";
import React from "react";
import { useMutation } from "react-query";
import { businessService } from "services";
import * as Yup from "yup";
import { BusinessDetailsForm } from "./BusinessDetailsForm";

interface BusinessFormProps
  extends FormBaseProps<BusinessFormInputType, BusinessModel> {
  businessType: BusinessType;
  user?: UserModel;
  admin?: AdministratorModel;
  business?: BusinessModel;
}

const businessFormSchema = Yup.object({
  name: Yup.string()
    .min(3)
    .test(
      "test-multiple-word",
      "Must contain at least two words",
      (value) => (value?.split(" ")?.length || 0) > 1
    )
    .required(),
  status: Yup.string().required(),
  type: Yup.string().required(),
  details: Yup.array()
    .of(
      Yup.object({
        businessId: Yup.string().nullable(),
        tag: Yup.string().required("Detail tag is required"),
        value: Yup.string().required("Detail value is required"),
        status: Yup.string().required("Detail status is required"),
      })
    )
    .required(),
});

export type BusinessFormInputType = Yup.InferType<typeof businessFormSchema>;

export const BusinessForm: React.FC<BusinessFormProps> = ({
  businessType,
  user,
  admin,
  business,
  onSubmit,
  onSuccess,
  onError,
}) => {
  const { toast } = useToast();
  const getBusinessLabel = (caps?: boolean) => {
    const businessTypeString = !!user
      ? "business"
      : businessType.split("_").join(" ");
    return caps ? capitalize(businessTypeString) : businessTypeString;
  };
  const defaultValues: BusinessFormInputType = {
    name: business?.name || "",
    status: business?.status || BusinessStatus.Inactive,
    type: business?.type || businessType,
    details: (business?.details as any) || [],
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    reset: resetForm,
  } = useFormWithSchema(businessFormSchema, {
    defaultValues,
  });

  const { mutate: mutateBusinessCreate, isLoading: isCreatingBusiness } =
    useMutation(businessService.create);
  const { mutate: mutateBusinessUpdate, isLoading: isUpdatingBusiness } =
    useMutation(businessService.update);

  const [isLoading, setLoading] = React.useState(false);

  const isSubmittingForm = React.useMemo(() => {
    return isCreatingBusiness || isUpdatingBusiness || isLoading;
  }, [isCreatingBusiness, isUpdatingBusiness, isLoading]);

  const errorHandler = (error: any) => {
    const message = error?.response?.data?.message || "Unknown error occurred";
    onError?.(new Error(message));
    toast({
      description: message,
      status: "error",
    });
  };

  const successHandler = (
    formValues: BusinessFormInputType,
    data: BusinessModel,
    toastMessage: string
  ) => {
    onSuccess?.(data);
    resetForm(formValues);
    toast({
      description: toastMessage,
      status: "success",
    });
  };

  const commitChanges = (values: BusinessFormInputType) => {
    const serializedValues = { ...values, details: values.details as any };
    if (business) {
      const payload = { ...business, ...serializedValues };
      mutateBusinessUpdate(payload, {
        onSuccess: () => successHandler(values, payload, "Updated"),
        onError: errorHandler,
      });
    } else {
      mutateBusinessCreate(serializedValues, {
        onSuccess: ({ data }) => successHandler(defaultValues, data, "Created"),
        onError: errorHandler,
      });
    }
  };

  const onFormSubmit = (values: BusinessFormInputType) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(values)
        .then((data) => resetForm(data))
        .finally(() => setLoading(false));
    } else {
      commitChanges(values);
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <VStack spacing="20px" alignItems="stretch">
        <FormGroup label="Name">
          <FormField
            autoComplete="name"
            placeholder={`Enter ${getBusinessLabel()} name`}
            errorMessage={errors.name?.message}
            disabled={!!business && !admin}
            {...register("name")}
          />
        </FormGroup>

        {!!business && !!admin && (
          <FormGroup label="Status">
            <FormField
              as="select"
              autoComplete="status"
              placeholder="Select status"
              errorMessage={errors.status?.message}
              {...register("status")}
            >
              {Object.entries(BusinessStatus).map(([key, status]) => (
                <option key={key} value={status}>
                  {key}
                </option>
              ))}
            </FormField>
          </FormGroup>
        )}
        <FormGroup label={`${getBusinessLabel(true)} detail`} display="none">
          <BusinessDetailsForm {...{ control, errors }} />
        </FormGroup>

        {!!admin && (
          <Button
            isLoading={isSubmittingForm}
            disabled={isSubmittingForm || !isValid || !isDirty}
            type="submit"
            data-test="submit-login"
            colorScheme="primary"
          >
            {!business ? "Create" : "Update"} {getBusinessLabel()}
          </Button>
        )}
        {isDirty && (
          <Button
            disabled={isSubmittingForm}
            data-test="reset-login"
            onClick={() => resetForm(defaultValues)}
          >
            Discard Changes
          </Button>
        )}
      </VStack>
    </form>
  );
};
