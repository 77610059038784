import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { BusinessDetailStatus, BusinessDetailTag } from "@obtainly-v2/enums";
import { BusinessDetailModel, BusinessModel } from "@obtainly-v2/schema";
import { FileItem, FileUploader, RadioCardGroup } from "components/common";
import { useToast } from "hooks";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { uploadService } from "services";
import { businessDetailService } from "services/BusinessDetail";

interface BusinessDetailDefaultProps {
  business: BusinessModel;
  tag: BusinessDetailTag;
  label: string;
  businessDetail?: BusinessDetailModel;
  isAdmin?: boolean;
  onUpdate?: VoidFunction;
}

interface FileDoc extends BusinessDetailDefaultProps {
  type?: "file";
  options?: undefined;
}
interface RadioDoc extends BusinessDetailDefaultProps {
  type: "radio";
  options: Array<{ label: string; value: string }>;
}

type BusinessDetailFormProps = FileDoc | RadioDoc;

export const BusinessDetailForm: React.FC<BusinessDetailFormProps> = ({
  business,
  tag,
  label,
  businessDetail,
  isAdmin,
  onUpdate,
  type,
  options,
}) => {
  const { toast } = useToast();
  const [verified, setVerified] = useState(
    () => businessDetail?.status === BusinessDetailStatus.Verified
  );

  const [newFile, setNewFile] = useState<File | null>(null);

  const { data: upload } = useQuery(
    `upload_read_${businessDetail?.value}`,
    () => uploadService.read(businessDetail?.value),
    {
      enabled: !!businessDetail?.value && type === "file",
    }
  );

  const fileUrl = useMemo(() => {
    return upload?.fileUrl;
  }, [upload]);

  const defaultMutationOptions = {
    onSuccess: () => {
      onUpdate?.();
    },
    onError: (error: any) => {
      const description =
        typeof error === "string"
          ? error
          : error?.response?.data?.message ||
            error?.message ||
            "An unexpected error has occurred!";
      toast({
        status: "error",
        description,
      });
    },
  };

  const createBizDetailMutation = useMutation(businessDetailService.create);
  const updateBizDetailMutation = useMutation(businessDetailService.update);
  const deleteBizDetailMutation = useMutation(businessDetailService.delete);

  const isLoading =
    createBizDetailMutation.isLoading || updateBizDetailMutation.isLoading;

  const uploadDocument = (uploadId: string) => {
    return new Promise<void>((resolve, reject) => {
      if (businessDetail) {
        // update
        updateBizDetailMutation.mutate(
          {
            _id: businessDetail._id,
            tag: businessDetail.tag,
            value: uploadId,
            status: isAdmin
              ? BusinessDetailStatus.Verified
              : BusinessDetailStatus.Unverified,
            businessId: businessDetail.businessId || business._id,
          },
          {
            onSuccess: () => {
              onUpdate?.();
              setVerified(!!isAdmin);
              toast({
                status: "success",
                description: isAdmin
                  ? `${label} marked as verified`
                  : "Document file updated",
              });
              setNewFile(null);
              resolve();
            },
            onError: (error: any) => {
              defaultMutationOptions.onError(error);
              reject();
            },
          }
        );
      } else {
        // create
        createBizDetailMutation.mutate(
          {
            tag,
            value: uploadId,
            status: isAdmin
              ? BusinessDetailStatus.Verified
              : BusinessDetailStatus.Unverified,
            businessId: business._id || "",
          },
          {
            onSuccess: () => {
              onUpdate?.();
              setVerified(!!isAdmin);
              toast({
                status: "success",
                description: isAdmin
                  ? `${label} marked as verified`
                  : "Document file updated",
              });
              setNewFile(null);
              resolve();
            },
            onError: (error: any) => {
              defaultMutationOptions.onError(error);
              reject();
            },
          }
        );
      }
    });
  };

  const submitDocumentValue = (value: string) => {
    if (businessDetail?.value === value) {
      return;
    }
    if (businessDetail) {
      updateBizDetailMutation.mutate(
        {
          _id: businessDetail._id,
          value,
          businessId: businessDetail.businessId || business._id,
          tag: businessDetail.tag,
          status: isAdmin
            ? BusinessDetailStatus.Verified
            : BusinessDetailStatus.Unverified,
        },
        {
          ...defaultMutationOptions,
          onSuccess: () => {
            setVerified(!!isAdmin);
            onUpdate?.();
            toast({
              status: "success",
              description: `${label} has been updated`,
            });
          },
        }
      );
    } else {
      createBizDetailMutation.mutate(
        {
          tag,
          value,
          status: isAdmin
            ? BusinessDetailStatus.Verified
            : BusinessDetailStatus.Unverified,
          businessId: business._id || "",
        },
        {
          ...defaultMutationOptions,
          onSuccess: () => {
            setVerified(!!isAdmin);
            onUpdate?.();
            toast({
              status: "success",
              description: `${label} was set successfully`,
            });
          },
        }
      );
    }
  };

  const verifyDocument = () => {
    if (!businessDetail) {
      toast({
        status: "error",
        description: "Invalid document",
      });
      return;
    }
    updateBizDetailMutation.mutate(
      {
        _id: businessDetail._id,
        value: businessDetail.value,
        businessId: businessDetail.businessId || business._id,
        tag: businessDetail.tag,
        status: BusinessDetailStatus.Verified,
      },
      {
        ...defaultMutationOptions,
        onSuccess: () => {
          onUpdate?.();
          setVerified(true);
          toast({
            status: "success",
            description: `${label} marked as verified`,
          });
        },
      }
    );
  };

  const undoVerifyDocument = () => {
    if (!businessDetail) {
      toast({
        status: "error",
        description: "Invalid document",
      });
      return;
    }
    updateBizDetailMutation.mutate(
      {
        _id: businessDetail._id,
        value: businessDetail.value,
        businessId: businessDetail.businessId || business._id,
        tag: businessDetail.tag,
        status: BusinessDetailStatus.Unverified,
      },
      {
        ...defaultMutationOptions,
        onSuccess: () => {
          onUpdate?.();
          setVerified(false);
          toast({
            status: "success",
            description: `${label} marked as unverified`,
          });
        },
      }
    );
  };

  const deleteDocument = () => {
    return new Promise<void>((resolve, reject) => {
      deleteBizDetailMutation.mutate(
        {
          _id: business._id,
          detail_id: businessDetail?._id,
        },
        {
          onSuccess: () => {
            defaultMutationOptions.onSuccess();
            resolve();
          },
          onError: (error: any) => {
            defaultMutationOptions.onError(error);
            reject();
          },
        }
      );
    });
  };

  return (
    <Box w="full">
      {type === "radio" ? (
        <Box w="full">
          <RadioCardGroup
            value={businessDetail?.value}
            onChange={submitDocumentValue}
            options={options}
            isDisabled={isLoading}
          />
        </Box>
      ) : (
        <>
          {!!fileUrl && !newFile && (
            <FileItem
              dataUrl={fileUrl}
              label={label}
              onChange={(file) => setNewFile(file)}
              onDelete={
                isAdmin ||
                businessDetail?.status === BusinessDetailStatus.Unverified
                  ? deleteDocument
                  : undefined
              }
            />
          )}
          {!!newFile && (
            <FileUploader
              file={newFile}
              onUpload={uploadDocument}
              tag={tag}
              label={label}
              onRemove={() => setNewFile(null)}
              onUploadError={defaultMutationOptions.onError}
              autoStart
            />
          )}
        </>
      )}

      {!!businessDetail && isAdmin ? (
        <Flex my="6px" gap="10px" alignItems="center">
          {verified ? (
            <>
              <Flex alignItems="center" gap="6px">
                <Text fontSize="sm" color="success.600">
                  {label} has been verified
                </Text>
              </Flex>

              <Button
                size="sm"
                variant="link"
                colorScheme="critical"
                onClick={undoVerifyDocument}
                isLoading={updateBizDetailMutation.isLoading}
                isDisabled={updateBizDetailMutation.isLoading}
              >
                Undo Verify Document
              </Button>
            </>
          ) : (
            <Button
              size="sm"
              colorScheme="success"
              onClick={verifyDocument}
              isLoading={updateBizDetailMutation.isLoading}
              isDisabled={updateBizDetailMutation.isLoading}
            >
              Verify Document
            </Button>
          )}
        </Flex>
      ) : verified ? (
        <>
          <Flex my="6px" alignItems="center" gap="6px">
            <Text fontSize="sm" color="success.600">
              {label} has been verified
            </Text>
          </Flex>
        </>
      ) : null}
    </Box>
  );
};
