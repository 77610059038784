import { Badge, Box, Flex, Text } from "@chakra-ui/layout";
import { UserModel } from "@obtainly-v2/schema";
import { Card, CardBody } from "components/common/Card";
import { useProfileCompletion } from "hooks";
import pluralize from "pluralize";
import { FC } from "react";
import { MdVerified } from "react-icons/md";

interface BusinessProfileStatusCardProps {
  user: UserModel | null;
}

export const BusinessProfileStatusCard: FC<BusinessProfileStatusCardProps> = ({
  user,
}) => {
  const {
    unset,
    unsetCount,
    unverified,
    unverifiedCount,
    requiredDocumentCount,
    isComplete,
  } = useProfileCompletion(user?.business);
  return (
    <Card>
      <CardBody>
        {!!isComplete && (
          <Flex align="center" gap="3px">
            <Box color="primary.600" fontSize="2xl">
              <MdVerified />
            </Box>
            <Text>Profile has been verified</Text>
          </Flex>
        )}
        {!!unsetCount && (
          <Box mb="15px">
            <Text fontWeight="medium">
              Missing {pluralize("document", unsetCount)} ({unsetCount}/
              {requiredDocumentCount})
            </Text>
            {Array.from(unset).map((tag, index) => (
              <Badge key={index} colorScheme="critical" mr="4px">
                {tag.replaceAll("_", " ")}
              </Badge>
            ))}
          </Box>
        )}
        {!!unverifiedCount && (
          <Box>
            <Text fontWeight="medium">
              Unverified {pluralize("document", unverifiedCount)} (
              {unverifiedCount}/{requiredDocumentCount})
            </Text>
            {Array.from(unverified).map((tag, index) => (
              <Badge
                key={index}
                colorScheme={unset.has(tag) ? "critical" : "warning"}
                mr="4px"
              >
                {tag.replaceAll("_", " ")}
              </Badge>
            ))}
          </Box>
        )}
      </CardBody>
    </Card>
  );
};
