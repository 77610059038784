import { Badge, Box, Button, Flex, Link } from "@chakra-ui/react";
import { BusinessStatus, BusinessType } from "@obtainly-v2/enums";
import { BusinessModel } from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { Table } from "components/common/Table";
import { useTableSort } from "hooks";
import { capitalize } from "lodash";
import NextLink from "next/link";
import React from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { businessService } from "services";
import { BusinessStatusBadge } from "./BusinessStatusBadge";

interface BusinessesTableProps {
  businessType: BusinessType;
  route?: string;
  hiddenColumns?: ("name" | "type" | "status" | "details" | "actions")[];
}

export const BusinessesTable: React.FC<BusinessesTableProps> = ({
  businessType,
  route,
  hiddenColumns,
}) => {
  const { tableQueryProps, updateTableQueryProps, initializeSorting } =
    useTableSort({
      page: 1,
      sort: { id: "createdAt", desc: true },
    });

  const columns = React.useMemo(() => {
    const defaultColumns: Column<BusinessModel>[] = [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "type",
        Header: "Type",
        accessor: "type",
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value: status } }) => (
          <BusinessStatusBadge status={status as BusinessStatus} />
        ),
      },
      {
        id: "details",
        Header: "Details",
        accessor: "details",
        Cell: ({ cell }) => {
          const detailsCount = cell.value?.length || 0;
          const hasMore = detailsCount > 3;
          const details =
            (hasMore ? cell.value?.slice(0, 2) : cell.value) || [];
          return (
            <>
              {detailsCount === 0 ? (
                "None"
              ) : (
                <>
                  {details.map((businessDetail, index) => (
                    <Badge
                      key={index}
                      mr="1"
                      textTransform="uppercase"
                      colorScheme={
                        businessDetail.status === "verified"
                          ? "warning"
                          : "gray"
                      }
                    >
                      <Flex align="center" gap="2px">
                        {businessDetail.status === "verified" && (
                          <RiCheckboxCircleFill />
                        )}
                        {businessDetail.tag}
                      </Flex>
                    </Badge>
                  ))}
                  {hasMore && (
                    <Badge key="more" mr="1">
                      +{detailsCount - 2} more
                    </Badge>
                  )}
                </>
              )}
            </>
          );
        },
      },
      {
        id: "actions",
        Header: "Actions",
        Cell: ({ row }: any) =>
          !!route ? (
            <NextLink href={`${route}/${row.original?._id}`} passHref>
              <Link>
                <Button size="sm" colorScheme="gray">
                  View
                </Button>
              </Link>
            </NextLink>
          ) : null,
      },
    ];
    if (hiddenColumns?.length) {
      return defaultColumns.filter(
        (column: any) => !hiddenColumns.includes(column.id)
      );
    }
    return defaultColumns;
  }, [hiddenColumns, route]);

  const { data: businesses, isLoading } = useQuery(
    `business_read_many_by_type_${businessType}`,
    () => businessService.readManyBy({ type: businessType })
  );

  React.useEffect(() => {
    if (!businesses?.length) return;
    initializeSorting();
  }, [businesses, initializeSorting]);

  return (
    <Box overflowX="auto">
      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!businesses?.length}
        errorMessage={`${capitalize(businessType)}s not found`}
      />
      {!!businesses?.length && (
        <Table
          columns={columns}
          data={businesses || []}
          queryProps={{
            ...tableQueryProps,
            onQueryChange: updateTableQueryProps,
          }}
        />
      )}
    </Box>
  );
};
