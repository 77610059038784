import { BusinessDetailModel } from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class BusinessDetailService extends ModelService {
  constructor() {
    super("business_detail");
  }

  update = (payload: Partial<BusinessDetailModel>) => {
    return this.api
      .patch(
        `/${this.modelName}/update?_id=${payload.businessId}&detail_id=${payload._id}`,
        payload
      )
      .then(({ data }) => data);
  };
}

export const businessDetailService = new BusinessDetailService();
