import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { BusinessDetailStatus, BusinessDetailTag } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  BusinessDetailModel,
  BusinessModel,
} from "@obtainly-v2/schema";
import { UploadModel } from "@obtainly-v2/schema/src/upload";
import { FileUploadField } from "components/common";
import { useToast } from "hooks";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { uploadService } from "services";
import { businessDetailService } from "services/BusinessDetail";

interface BusinessDocumentsProps {
  business: BusinessModel;
  tag: BusinessDetailTag;
  label: string;
  detail?: BusinessDetailModel;
  admin?: AdministratorModel;
  onUpdate?: VoidFunction;
}

export const BusinessDocument: React.FC<BusinessDocumentsProps> = ({
  business,
  tag,
  label,
  detail,
  admin,
  onUpdate,
}) => {
  const { toast } = useToast();
  const [verified, setVerified] = useState(
    () => detail?.status === BusinessDetailStatus.Verified
  );

  const { data: upload } = useQuery(
    `read_upload_${detail?.value}`,
    () => uploadService.read(detail?.value),
    { enabled: !!detail?.value }
  );

  const defaultMutationOptions = {
    onSuccess: () => {
      onUpdate?.();
    },
    onError: () => {
      toast({
        status: "error",
        description: "An unexpected error has occurred!",
      });
    },
  };

  const createBusinessDetailMutation = useMutation(
    businessDetailService.create,
    defaultMutationOptions
  );
  const updateBusinessDetailMutation = useMutation(
    businessDetailService.update,
    defaultMutationOptions
  );

  const uploadDocument = (data: UploadModel) => {
    createBusinessDetailMutation.mutate({
      tag,
      value: data._id,
      status: BusinessDetailStatus.Unverified,
      businessId: business?._id,
    });
  };

  const verifyDocument = () => {
    updateBusinessDetailMutation.mutate(
      {
        ...detail,
        status: BusinessDetailStatus.Verified,
        businessId: business?._id,
      },
      {
        onSuccess: () => {
          setVerified(true);
          toast({
            status: "success",
            description: `${label} marked as verified`,
          });
        },
      }
    );
  };

  const undoVerifyDocument = () => {
    updateBusinessDetailMutation.mutate(
      {
        ...detail,
        status: BusinessDetailStatus.Unverified,
        businessId: business?._id,
      },
      {
        onSuccess: () => {
          setVerified(false);
          toast({
            status: "success",
            description: `${label} marked as unverified`,
          });
        },
      }
    );
  };

  return (
    <Box>
      <FileUploadField
        autoStartUpload
        tag={tag}
        businessId={business?._id}
        label={label}
        fileUrl={upload?.fileUrl}
        onSuccess={uploadDocument}
      />

      {!!detail && !!admin ? (
        <Flex mt="10px" gap="10px" alignItems="center">
          {verified ? (
            <>
              <Flex alignItems="center" gap="6px">
                <Text fontSize="sm" color="success.600">
                  {label} has been verified
                </Text>
              </Flex>

              <Button
                size="sm"
                variant="link"
                colorScheme="critical"
                onClick={undoVerifyDocument}
                isLoading={updateBusinessDetailMutation.isLoading}
                isDisabled={updateBusinessDetailMutation.isLoading}
              >
                Undo Verify Document
              </Button>
            </>
          ) : (
            <Button
              size="sm"
              colorScheme="success"
              onClick={verifyDocument}
              isLoading={updateBusinessDetailMutation.isLoading}
              isDisabled={updateBusinessDetailMutation.isLoading}
            >
              Verify Document
            </Button>
          )}
        </Flex>
      ) : verified ? (
        <>
          <Flex mt="8px" alignItems="center" gap="6px">
            <Text fontSize="sm" color="success.600">
              {label} has been verified
            </Text>
          </Flex>
        </>
      ) : null}
    </Box>
  );
};
