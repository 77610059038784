import { Box, Button, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessStatus, BusinessType, UserRole } from "@obtainly-v2/enums";
import { useAuth, UserType } from "hooks";
import { useToast } from "hooks/useToast";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { userAuthService } from "services";
import { setAccessToken } from "utils";
import * as Yup from "yup";
import { FormField, FormGroup, PasswordValidator } from "../common";

const schema = Yup.object({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last name"),
  email: Yup.string().email().required().label("Email"),
  phone: Yup.string()
    .matches(/^[0][7-9][0-1][0-9]{8}$/, "Phone number must be valid.")
    .required()
    .label("Phone number"),
  password: Yup.string()
    .required()
    .min(8)
    .matches(/[a-z]/, "Password must contain one lowercase letter")
    .matches(/[A-Z]/, "Password must contain one uppercase letter")
    .matches(/[0-9]/, "Password must contain one number")
    .label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .label("Confirm password"),
  businessName: Yup.string()
    .required()
    .test(
      "test-multiple-word",
      "Must contain at least two words",
      (value) => (value?.split(" ")?.length || 0) > 1
    )
    .label("Business name"),
});

export type BusinessSignUpFormInputType = Yup.InferType<typeof schema>;

interface BusinessSignUpFormProps {
  businessType: BusinessType;
  onSuccess?: () => void;
}

export const BusinessSignUpForm: React.FC<BusinessSignUpFormProps> = ({
  businessType,
  onSuccess,
}) => {
  const { toast } = useToast();
  const { setAuth } = useAuth();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
  } = useForm<BusinessSignUpFormInputType>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      businessName: "",
    },
    mode: "all",
  });

  const { mutate: signup, isLoading } = useMutation(userAuthService.register);

  const onSubmit = (values: BusinessSignUpFormInputType) => {
    signup(
      {
        business: {
          name: values.businessName,
          type: businessType,
          status: BusinessStatus.Inactive,
        },
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          password: values.password,
          role: UserRole.Admin,
          promptPasswordChange: false,
        },
      },
      {
        onSuccess: ({ data }) => {
          onSuccess?.();
          setAccessToken(data.auth.accessToken, UserType.User);
          setAuth((state) => ({
            ...state,
            userType: UserType.User,
            user: data.data,
          }));
          toast({
            description: "You account has been created.",
            status: "success",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.message || "An unknown error occurred.",
            status: "error",
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="20px" alignItems="stretch">
        <FormGroup label="Business Name" mb="0">
          <FormField
            errorMessage={errors.businessName?.message}
            {...register("businessName")}
          />
        </FormGroup>

        <FormGroup label="First Name" mb="0">
          <FormField
            errorMessage={errors.firstName?.message}
            {...register("firstName")}
          />
        </FormGroup>

        <FormGroup label="Last Name" mb="0">
          <FormField
            errorMessage={errors.lastName?.message}
            {...register("lastName")}
          />
        </FormGroup>

        <FormGroup label="Email Address" mb="0">
          <FormField
            type="email"
            autoComplete="email"
            errorMessage={errors.email?.message}
            {...register("email")}
          />
        </FormGroup>

        <FormGroup label="Phone Number" mb="0">
          <FormField
            errorMessage={errors.phone?.message}
            {...register("phone")}
          />
        </FormGroup>

        <FormGroup label="Password" mb="0">
          <FormField
            type="password"
            hasError={!!errors.password?.message}
            {...register("password")}
          />
          {touchedFields.password && errors.password?.message ? (
            <Box mt="8px" ml="15px">
              <PasswordValidator
                password={watch("password")}
                validations={{
                  length: true,
                  uppercaseCharacter: true,
                  lowercaseCharacter: true,
                  number: true,
                }}
              />
            </Box>
          ) : null}
        </FormGroup>

        <FormGroup label="Confirm Password" mb="0">
          <FormField
            type="password"
            errorMessage={errors.confirmPassword?.message}
            {...register("confirmPassword")}
          />
        </FormGroup>

        <Button
          isLoading={isLoading}
          isDisabled={isLoading || !isValid}
          type="submit"
          colorScheme="primary"
        >
          Continue
        </Button>
      </VStack>
    </form>
  );
};
