import { Box, BoxProps, FlexProps, Heading, Text } from "@chakra-ui/layout";
import {
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from "@chakra-ui/system";
import React from "react";

interface CardProps extends BoxProps {
  [value: string]: any;
  noBackgroundOnMobile?: boolean;
}
export const Card: React.FC<CardProps> = ({
  noBackgroundOnMobile,
  ...props
}) => {
  const { children, ...rest } = props;

  const styles = useMultiStyleConfig("Card", {});
  // Pass the computed styles into the `__css` prop

  const mobileStyles: BoxProps = {};

  if (noBackgroundOnMobile) {
    mobileStyles.bg = ["transparent", "transparent", "white"];
    mobileStyles.boxShadow = ["none", "none", ""];
  }

  return (
    <Box __css={styles.card} {...mobileStyles} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
};

type CardHeaderProps = FlexProps & {
  heading?: string;
  headingSize?: "sm";
  description?: string;
};
export const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  heading,
  headingSize,
  description,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Box as="header" __css={styles.header} {...rest}>
      {!!heading && (
        <Box>
          <Heading
            fontSize={headingSize === "sm" ? "14px" : "16px"}
            fontWeight="medium"
            mb="4px"
          >
            {heading}
          </Heading>
          <Text color="gray.600" fontSize="sm">
            {description}
          </Text>
        </Box>
      )}
      {children}
    </Box>
  );
};

export const CardBody: React.FC<BoxProps> = (props) => {
  const styles = useStyles();
  return <Box __css={styles.body} {...props} />;
};

export const CardFooter: React.FC<BoxProps> = (props) => {
  const styles = useStyles();
  return <Box __css={styles.footer} {...props} />;
};
