import { Box, Button } from "@chakra-ui/react";
import { BusinessDetailStatus, BusinessDetailTag } from "@obtainly-v2/enums";
import { BusinessDetailModel, BusinessModel } from "@obtainly-v2/schema";
import { FileSelector, FileUploader } from "components/common";
import { useToast } from "hooks";
import { debounce } from "lodash";
import { FC, Fragment, useRef, useState } from "react";
import { useMutation } from "react-query";
import { businessDetailService } from "services/BusinessDetail";
import { BusinessDetailForm } from "./BusinessDetailForm";

interface BusinessDetailListProps {
  label: string;
  tag: BusinessDetailTag;
  business: BusinessModel;
  businessDetails?: Array<BusinessDetailModel>;
  refetch: VoidFunction;
  isAdmin?: boolean;
  allowMultipleFiles?: boolean;
}

export const BusinessDetailList: FC<BusinessDetailListProps> = ({
  label,
  tag,
  business,
  businessDetails,
  refetch,
  isAdmin,
  allowMultipleFiles,
}) => {
  const { toast } = useToast();
  const buttonRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<Map<string, File>>(new Map());

  const addSelectedFiles = (fileMap: Map<string, File>) => {
    setFiles((prevFiles) => {
      const nextFiles = new Map(prevFiles);
      for (const [key, file] of Array.from(fileMap)) {
        nextFiles.set(key, file);
      }
      return nextFiles;
    });
  };

  const removeFile = (key: string) => {
    setFiles((prevFiles) => {
      const nextFiles = new Map(prevFiles);
      nextFiles.delete(key);
      return nextFiles;
    });
  };

  const onError = (error: any) => {
    const description =
      typeof error === "string"
        ? error
        : error?.message || "An unexpected error has occurred!";
    toast({
      status: "error",
      description,
    });
  };

  const deferredRefetch = debounce(refetch, 100);

  const createBusinessDocMutation = useMutation(businessDetailService.create);

  const uploadDocument = (uploadId: string) => {
    deferredRefetch.cancel();
    return new Promise<void>((resolve, reject) => {
      //
      createBusinessDocMutation.mutate(
        {
          tag,
          value: uploadId,
          status: isAdmin
            ? BusinessDetailStatus.Verified
            : BusinessDetailStatus.Unverified,
          businessId: business._id || "",
        },
        {
          onSuccess: () => {
            toast({
              status: "success",
              description: isAdmin
                ? `${label} marked as verified`
                : "Document file updated",
            });
            resolve();
          },
          onError: (error: any) => {
            onError(error);
            reject(error);
          },
        }
      );
    });
  };

  return (
    <Box>
      {businessDetails?.map((detail, index) => (
        <Fragment key={index}>
          <BusinessDetailForm
            label={label}
            business={business}
            tag={tag}
            businessDetail={detail}
            isAdmin={isAdmin}
            onUpdate={() => refetch()}
            type="file"
          />
        </Fragment>
      ))}

      {Array.from(files.entries())?.map(([key, file]) => (
        <FileUploader
          key={key}
          tag={tag}
          label={label}
          file={file}
          onUpload={uploadDocument}
          onUploadError={onError}
          onRemove={() => {
            removeFile(key);
            deferredRefetch();
          }}
          autoStart
        />
      ))}

      <FileSelector
        onSelect={addSelectedFiles}
        multiple={allowMultipleFiles}
        ref={buttonRef}
      />
      {(allowMultipleFiles ||
        !businessDetails ||
        (businessDetails && businessDetails?.length === 0)) && (
        <Button mt="10px" size="sm" onClick={() => buttonRef.current?.click()}>
          Upload {businessDetails?.length ? "more" : ""} file
          {allowMultipleFiles ? "s" : ""}
        </Button>
      )}
    </Box>
  );
};
