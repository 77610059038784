import { Text } from "@chakra-ui/react";
import { BusinessType } from "@obtainly-v2/enums";
import { Autocomplete, AutocompleteProps } from "components/common";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { businessService } from "services";

export interface BusinessAutocompleteProps extends AutocompleteProps {
  businessType: BusinessType;
  errorMessage?: string;
}

export const BusinessAutocomplete: React.FC<BusinessAutocompleteProps> = ({
  businessType,
  isDisabled,
  errorMessage,
  ...rest
}) => {
  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const { mutate: mutateCreate, isLoading: isCreating } = useMutation(
    businessService.create
  );
  const { data: businesses, isLoading: isReadingAll } = useQuery(
    `business_read_many_by_type_${businessType}`,
    () => businessService.readManyBy({ type: businessType }),
    {
      enabled: !!businessType,
    }
  );

  const handleCreate = (inputValue: string) => {
    const payload = {
      name: inputValue,
      type: businessType,
      status: "active",
    };
    mutateCreate(payload, {
      onSuccess: ({ data }) => {
        const newOption = {
          value: data._id,
          label: data.name,
        };
        rest?.onChange(newOption);
        setOptions((options) => [...options, newOption]);
      },
    });
  };

  React.useEffect(() => {
    const newBusinesses =
      businesses
        ?.filter((business) => business.type === businessType)
        ?.map((business) => ({
          label: business.name,
          value: business._id,
        })) || [];
    setOptions(newBusinesses);
  }, [businesses, businessType]);

  return (
    <>
      <Autocomplete
        options={options}
        isDisabled={isCreating || isReadingAll || !!isDisabled}
        onCreateOption={handleCreate}
        {...rest}
      />
      {!!errorMessage && (
        <Text fontSize="sm" color="red.500" mt="2px">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
