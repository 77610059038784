import { Badge, BadgeProps } from "@chakra-ui/react";
import { BusinessStatus } from "@obtainly-v2/enums";

export const BusinessStatusBadge = ({
  status,
  ...badgeProps
}: BadgeProps & {
  status: BusinessStatus;
}) => {
  return (
    <Badge
      colorScheme={
        status === BusinessStatus.Active
          ? "success"
          : status === BusinessStatus.Suspended
          ? "critical"
          : "gray"
      }
      {...badgeProps}
    >
      {status}
    </Badge>
  );
};
